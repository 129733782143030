<script setup lang="ts">
interface MenuItem {
  title: string
  to?: string
  about?: string
  children?: MenuItem[]
}

defineProps({
  level: {
    type: Number,
    required: true,
  },
  menuItems: {
    type: Array as PropType<MenuItem[]>,
    required: true,
  },
})

const menuOpen: Ref<null | number> = ref(null)

function toggleMenuOpen(key: number) {
  menuOpen.value = menuOpen.value === key ? null : key
}
</script>

<template>
  <li v-for="(menuItem, key) in menuItems" :key="key" :style="{ marginLeft: `${level * 10}px` }">
    <span
      class="section-opener flex justify-between" :class="{
        'py-4': level === 0,
        'py-3': level > 0,
        'cursor-pointer': menuItem.children,
      }" @click="toggleMenuOpen(key)"
    >
      <span>{{ menuItem.title }}</span>
      <Icon
        v-if="menuItem.children" name="heroicons:chevron-down-20-solid" class="w-8 h-8 duration-300" :class="{
          '-rotate-180': menuOpen === key,
        }"
      />
    </span>
    <ul
      v-if="menuItem.children" class="section overflow-hidden duration-700 transition-all" :class="{
        'max-h-0': menuOpen !== key,
        'max-h-[500px]': menuOpen === key,
      }"
    >
      <MenuItem :level="level + 1" :menu-items="menuItem.children" />
    </ul>
  </li>
</template>
