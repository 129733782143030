<script setup lang="ts">
const consumersMenuItems = [
  {
    title: 'HR+, HER2 - High-Risk Early Breast Cancer',
    to: '#',
    children: [
      { title: 'About Early Breast Cancer' },
      { title: 'About Verzenio' },
      { title: 'Taking Verzinio' },
      { title: 'Patient Stories' },
    ],
  },
  {
    title: 'HR+, HER2 - Metastatic Breast Cancer',
    children: [
      { title: 'About Metastatic Breast Cancer' },
      { title: 'About Verzenio' },
      { title: 'Taking Verzinio', children: [
        { title: 'Taking Verzinio' },
        { title: 'Verzenio + Aromatase Inhibitor (AI)' },
        { title: 'Verzenio + Fulvestrant' },
        { title: 'Verzenio by Itself' },
      ] },

      { title: 'Patient Stories' },
    ],
  },
  { title: 'Addressing Side Effects' },
  { title: 'Savings & Support' },
  { title: 'Pricing Information' },
]
</script>

<template>
  <ul class="menu border-y border-neutral-300 divide-y divide-neutral-200">
    <li>
      <span class="py-4 px-6 flex items-center bg-neutral-200 font-bold space-x-3 border-l-4 border-black">
        <Icon name="heroicons:home-20-solid" class="w-4 h-4" />
        <span>For Consumers</span>
      </span>

      <ul class="px-8 bg-neutral-100">
        <DemosLillyMenuItem :level="0" :menu-items="consumersMenuItems" />
      </ul>
    </li>
    <li>
      <span class="py-4 px-6 flex items-center space-x-3">

        <span>For Healthcare Providers</span>
      </span>
    </li>
    <li>
      <span class="py-4 px-6 flex items-center space-x-3">
        <Icon name="heroicons:chat-bubble-left-20-solid" class="w-4 h-4" />
        <span>Contact Us</span>
      </span>
    </li>
  </ul>
</template>
